import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(!_vm.detail)?_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600","color":"primary-light"}},[_c(VCardText,[_c(VCardTitle,[_vm._v(" Select Organization ")]),_c(VTextField,{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.searchOrganization),callback:function ($$v) {_vm.searchOrganization=$$v},expression:"searchOrganization"}}),(_vm.loadingOrganizations)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{staticClass:"mt-10 mb-10",attrs:{"size":"40","color":"primary","indeterminate":""}})],1):_c(VList,{staticStyle:{"overflow":"scroll"},attrs:{"color":"primary-light","nav":"","max-height":"400"}},[_c(VListItemGroup,_vm._l((_vm.sort({
                items: _vm.search({
                  items: _vm.organizationList,
                  query: _vm.searchOrganization,
                  fields: _vm.organizationSearchFields
                }),
                field: 'data.name'
              })),function(org){return _c(VListItem,{key:org.id,attrs:{"to":("/organizations/" + (org.id))}},[_c(VListItemIcon,[_vm._v(" "+_vm._s(org.data.key)+" ")]),_c(VListItemContent,[_vm._v(" "+_vm._s(org.data.name)+" ")])],1)}),1)],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }